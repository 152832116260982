import React, { useEffect, useContext } from "react"
import { graphql, Link } from "gatsby"
import { useIntl } from "react-intl"

import { IntlContext } from "../../intl"
import Layout from "../layout"
import SEO from "../seo"
// import AppLoading from "../components/app/app-loading"

export const query = graphql`
	query($id: String!) {
		prismicNotFound(id: { eq: $id }) {
      uid
      lang
			data {
				title {
					text
				}
				headline {
					text
				}
        above_the_fold_image {
          alt
          fluid {
            src
          }
        }
			}
      alternate_languages {
        lang
        url
      }
		}
	}
`

const NotFoundPage = ({ location, data }) => {

	const intl = useIntl()

  const { changeLocale, setAlts } = useContext(IntlContext)
  useEffect(() => {
    changeLocale(data.prismicNotFound.lang);
    setAlts(data.prismicNotFound.alternate_languages || [])
	}, [changeLocale, setAlts, data])
	
  if (typeof window !== 'undefined') {
    return (
			<Layout container={false}>
				<SEO title={'404: '+data.prismicNotFound.data.title.text} />
				<div className="hero is-link">
					<div className="hero-body">
						<div className="container">
							<div className="columns is-vcentered">
								<div className="column is-three-fifths">
									<h1 className="title">{data.prismicNotFound.data.title.text}</h1>
									<h2 className="subtitle">{data.prismicNotFound.data.headline.text}</h2>
								</div>
								<div className="column is-two-fifths">
									<figure className="image">
										<img src={data.prismicNotFound.data.above_the_fold_image.fluid.src}
											alt={data.prismicNotFound.data.above_the_fold_image.alt} />
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section">
					<div className="container">
						<div className="columns">
							<div className="column">
								<div className="box">
									<h3 className="subtitle">
										<Link to={intl.formatMessage({ id: 'url.about' })}>
											{intl.formatMessage({ id: 'nav.about' })}
										</Link>
									</h3>
								</div>
							</div>
							<div className="column">
								<div className="box">
									<h3 className="subtitle">
										<Link to={intl.formatMessage({ id: 'url.products-services' })}>
											{intl.formatMessage({ id: 'nav.products_services' })}
										</Link>
									</h3>
								</div>
							</div> 
							<div className="column">
								<div className="box">
									<h3 className="subtitle">
										<Link to={intl.formatMessage({ id: 'url.contact' })}>
											{intl.formatMessage({ id: 'nav.contact' })}
										</Link>
									</h3>
								</div>
							</div> 
						</div>
					</div>
				</div>
			</Layout>
		)
	} else {
		// TODO
		return <p>Loading...</p>
  }
}

export default NotFoundPage
